var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables-view","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('h3',{staticClass:"mt-3"},[_vm._v("Subscribers")]),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.convertFormat(_vm.subscribers),"items-per-page":10,"sort-by":_vm.sortBy,"sort-desc":_vm.sortStartDateDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortStartDateDesc=$event},"update:sort-desc":function($event){_vm.sortStartDateDesc=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.isActive(item.status)},on:{"change":function($event){return _vm.onChange($event, item.id)}}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullName)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subscriber.email)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.lastSentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.lastSentDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","plain":"","color":"error"},on:{"click":function($event){return _vm.onRemove(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1)],1)],1),_c('confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }