<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-row>
    
      <v-col md="12">
        <h3 class="mt-3">Subscribers</h3>
        <v-row>
          <v-col>
            <v-card elevation="2">
              <v-data-table
                :headers="headers"
                :items="convertFormat(subscribers)"
                :items-per-page="10"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortStartDateDesc"
                class="elevation-1"
              >
                <template v-slot:item.active="{ item }">
                  <input
                    type="checkbox"
                    :checked="isActive(item.status)"
                    @change="onChange($event, item.id)"
                  />
                </template>
                <template v-slot:item.name="{ item }">
                  {{ item.fullName }}
                </template>
                <template v-slot:item.email="{ item }">
                  {{ item.subscriber.email }}
                </template>
                <template v-slot:item.createdAt="{ item }">
                  {{ formatDate(item.createdAt) }}
                </template>
                <template v-slot:item.lastSentDate="{ item }">
                  {{ formatDate(item.lastSentDate) }}
                </template>
                
                <template v-slot:item.actions="{ item }">
                  <v-btn small plain color="error" @click="onRemove(item.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import ConfirmDialog from "../components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog },
  computed: {
    ...mapGetters({
      subscribers: "getSubscribers",
    }),
  },
  methods: {
    isActive(status) {
      return status === "active";
    },
    ...mapActions({
      getSubscribers: "getSubscribers",
      updateSubscriptionStatus: "updateSubscriptionStatus",
      deleteSubscription: "axDeleteSubscription",
    }),
    convertFormat(data) {
      data.map((item) => {
        item.fullName = `${item.subscriber.firstName} ${item.subscriber.lastName}`;
      });
      return data;
    },
    formatDate(date) {
      return date ? moment(date).calendar() : " -- ";
    },
    onChange(event, subId) {
      this.updateSubscriptionStatus({
        subId,
        status: event.target.checked ? "active" : "inactive",
      });
    },
    async onRemove(subId) {
      const dareMe = await this.$refs.confirm.open(
        "Confirm",
        "Are you sure you want to delete this record?"
      );

      if (dareMe) {
        await this.deleteSubscription({
          subId,
        });
        const programId = this.$route.params.programId;
        // debugger
        this.getSubscribers(programId);
      }
    },
  },
  data() {
    return {
      sortBy: "email",
      sortStartDateDesc: true,
      headers: [
        {
          text: "",
          value: "active",
          sortable: false,
        },
        {
          text: "Name",
          value: "fullName",
          cellClass: "no-wrap",
        },
        {
          text: "Email",
          value: "subscriber.email",
        },
        {
          text: "Enrolled",
          value: "createdAt",
        },
        {
          text: "Last Sent",
          value: "lastSentDate",
        },
        {
          text: "Delete",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    const programId = this.$route.params.programId;
    this.getSubscribers(programId);
    this.subscribers.map((item) => console.log(item));
  },
};
</script>
